* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'DM Sans',
  sans-serif;
  background: #030408;
}

.SVG {
  width: 80%;
  height: auto;
  align-self: center;

}
@media only screen and (max-width: 550px) {
  .SVG {
    width: 100%;
  }
  /* .faq-0 {
    max-width: 35%;
  }
  
  .faq-1 {
    max-width: 35%;
  }
  
  .faq-2 {
    max-width: 35%;
  }
  
  .faq-3 {
    max-width: 35%;
  }
  
  .faq-4 {
    max-width: 35%;
  }
  
  */
  .faq-5 {
    margin-bottom: auto !important;
    }
}

/*
.faq-0 {
  max-width: 40%;
}
 .faq-1 {
  max-width: 40%;
}

.faq-2 {
  max-width: 40%;
}

.faq-3 {
  max-width: 40%;
}

.faq-4 {
  max-width: 40%;
}

} 
*/
.faq-5 {
  /* max-width: 40%; */
   margin-bottom: auto !important;
}

body {
  overflow-x: hidden;
  max-width: 100vw;
}